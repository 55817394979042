@font-face {
  font-family: 'One Size';
  src: url('../public/fonts/onesize.ttf') format('truetype');
}

.intro-text {
  color: green;
  font-family: 'One Size', monospace;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px; 
  text-transform: uppercase; 
}

.terminal {
  background-color: black;
  color: green;
  font-family: 'One Size', monospace;
  font-size: 30px;
  font-weight: bold;
  padding: 10vw 30vw 10vw 30vw;
  text-align: left;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex; 
  flex-direction: column; 
  justify-content: flex-start;
}

.terminal input {
  background-color: black;
  color: green;
  border: none;
  outline: none;
  font-family: 'One Size', monospace;
  font-size: 30px; 
  font-weight: bold; 
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 3vw;
}

.terminal pre {
  font-family: 'One Size', monospace;
  font-size: 30px; 
  font-weight: bold; 
  margin: 0;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word; 
  overflow-wrap: break-word; 
}

.terminal-textarea {
  overflow-y: scroll;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.terminal-textarea::-webkit-scrollbar {
  width: 0;
  height: 0;
}